const defaultViewAngleId = 'overview'

const defaultPreviewSize = { width: 1200, height: 630 }

const viewAngles = {
  overview: {
    label: 'Overview',
    exposeForUI: true,
  },
  barrel: {
    label: 'Barrel',
    exposeForUI: true,
  },
  handle: {
    label: 'Handle',
    exposeForUI: true,
  },
  end: {
    label: 'End',
    exposeForUI: true,
  },
  knob: {
    label: 'Knob',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: defaultPreviewSize,
  },
  front: {
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: defaultPreviewSize,
  },
  barrelEnd: {
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: { width: 1000, height: 1200 },
  },
}

export default viewAngles
export { defaultViewAngleId }
